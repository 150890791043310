@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&subset=latin-ext');
@import "vars";
@import "mixins";
@import "prism";
// @import "cookie-seal";

html {
    position: relative;
    min-height: 100%;
    width: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: $white;
    color: $black;
    // margin-bottom: ($footer-height * 2);
    margin-bottom: $footer-height;
    overflow-x: hidden;

    //Language Options
    .lang-tr {
        // opacity: .5;
    }
}


header {
    min-height: 10rem;
    // background-col
    @include gradientbg();
    padding: 2rem;

    h2 {
        font-size: 2.1rem !important;
        font-weight: 400;
        color: $orange;
        display: inline-block;
        letter-spacing: .5rem;
    }

    h5 {
        display: inline-block;
        p {
            font-size: .8rem !important;
            font-weight: 400;
            max-width: 36ch;
            // min-width: 36ch;
            text-align: left;
        }
        
    }

    @include media-breakpoint-down(md) {
        padding: 1rem;
        margin-left: 0 !important;
        margin-right: 0 !important;

        h2 {
            font-size: .95rem !important;
            font-weight: bold;
        }

        h5 {
            font-size: .8rem !important;
        }
    }

    .site-logo {
        padding-left: 1rem;
        max-height: 85px;

        @include media-breakpoint-down(md) {
            padding-bottom: 1rem;
        }
    }

    a,
    a:hover {
        color: $white;
        text-decoration: none;
    }
}

#heroimg {
    background: url(../gfx/santral-cover-02.jpg);
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center; 
    min-height: $cover-image;
}

nav {
    // background-color: lighten($theme-main, 10%) !important;
    @include gradientbg();
    border-top: 1px solid $lightblue;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    height: $nav-bar-height;
    .nav-link {
        color: $blue !important;
    }
}

@include media-breakpoint-down(md) {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .nav-item {
        text-align: center;
        

    .navbar-collapse {
        background-color: $dark;
        padding: 1rem;
    }

    form {
        justify-content: space-between;
    }

    input {
        flex: 1;
        margin-right: 1rem;
    }
}

.navbar-toggler {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5rem;
}

.dropdown-menu-right {
    @include media-breakpoint-down(md) {
        text-align: center !important;

        .fa-check-circle {
            display: none;
        }
    }
}

.dropdown-menu {
    // background-color: lighten($theme-main, 10%) !important;
    @include gradientbg();
    border-top: 1px solid $gray;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .dropdown-item {
        color: $blue !important;
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

#lang-select {
    margin-left: 1rem;

    .dropdown-item {
        // text-transform: uppercase;
    }

    .fa-globe {
        font-size: 1.5rem;
    }

    .fa-check-circle {
        padding-right: 1rem;
    }
}
}

.dropdown-menu {
    background-color: $dark;

    // a {
    //     color: darken($white, 40%) !important;
    // }

    .dropdown-item {
        color: $blue !important;

        &:hover {
            background-color: $blue;
            color: $black !important;
        }
    }

    .active {
        background-color: $blue;
        color: $black !important;
    }
}
.cover-image {
    min-height: $cover-image;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-down(md) {
        height: 200px;
    }

    img {
        position: absolute;
        margin: auto;
        border-radius: 0;
        min-height: 100%;
        min-width: 100%;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
    }
}

#content {
    //Global 
    display: grid;
    padding-bottom: 2rem;

    @include media-breakpoint-down(md) {
        padding-bottom: $footer-height * 2 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // @include animation;
        margin-top: 1rem;
    }

    a,
    a:hover {
        color: $darkgray;

        .category-link {
            background-color: $gray;
            color: $light;

            &:hover {
                text-decoration: none;
            }
        }
    }

    pre {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 1rem !important;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }

    // For center and middle of Content area
    .content-full-height {
        height: calc(100vh - (#{$nav-bar-height * 4} + #{$footer-height}));
    }

    // Right Column
    .date {
        font-size: .9rem;

        span {
            display: inline-block;
            min-width: 5rem;
        }
    }

    .badge {
        a {
            padding: .2rem;
            color: $white;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .share {
        span {
            vertical-align: top;

            &:first-child {
                line-height: 2.2rem;
                margin-right: 1rem;
            }
        }
    }

    #sidemenu {
        .nav-link[data-toggle].collapsed:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: " \f0d7";
        }

        .nav-link[data-toggle]:not(.collapsed):after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: " \f0d8";
        }
    }

    //Images    
    .img-thumbnail {
        border: none;
        background-color: transparent;
        border-radius: $image-radius;
    }

    .testimonial-avatar {
        // clip-path: circle(200px at center);
        // border-radius: 50%;
        // clip-path: circle(200px at center);
        max-width: 200px;
        border-radius: $image-radius;

    }

    .author-avatar {
        // max-width: 150px;
        border-radius: $image-radius;
    }

    .page-author {
        max-width: 100px;
        height: auto;
        padding-left: .5rem;

        img {
            border-radius: $image-radius * .5;
        }
    }

    .seller-avatar {
        max-width: 50px;
        height: auto;
        border: none;
        border-radius: $image-radius;
    }

    .carousel-buttons {
        a {
            color: $white;
        }
    }

    .gallery {
        .gallery-wrapper {
            margin: calc(#{$home-item-radius} * -1);
            line-height: 0;
            -webkit-column-count: 4;
            -webkit-column-gap: 0;
            -moz-column-count: 4;
            -moz-column-gap: 0;
            column-count: 4;
            column-gap: 0;

            @include media-breakpoint-down(md) {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }

            a {
                width: 100% !important;
                height: auto !important;
                float: left;

                &:hover {
                    text-decoration: none !important;
                }
            }
        }
    }

    //Archive Pages
    .archive {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }

        .row {
            padding: 0;
        }

        .list-image {
            overflow: hidden;
            margin-right: .5rem;
            border-radius: $image-radius * .5;

            @include media-breakpoint-down(md) {
                order: -1;
                margin-bottom: .5rem;
                margin-right: 0;
                margin-top: .5rem;
            }

            img {
                position: absolute;
                border-radius: $image-radius * .5;
                margin: auto;
                min-height: 100%;
                min-width: 100%;
                left: -100%;
                right: -100%;
                top: -100%;
                bottom: -100%;

                @include media-breakpoint-down(md) {
                    min-width: 3rem !important;
                    min-height: auto !important;
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    .tag-pills {
        font-size: .9rem;
        text-transform: uppercase;

        .list-group {
            padding: 2rem 0;

            @include media-breakpoint-down(md) {
                padding: 2rem 0 0 0;
            }
        }

        .list-group-item-action:hover,
        .list-group-item-action:focus {
            background-color: lighten($black, 95%);
        }

        .list-group-item {
            border-color: $black;
            color: $black;
            text-align: center;

            &:first-child {
                border-bottom-left-radius: $home-item-radius;
                border-top-left-radius: $home-item-radius;

                @include media-breakpoint-down(md) {
                    border-bottom-left-radius: 0 !important;
                    border-top-left-radius: $home-item-radius;
                    border-top-right-radius: $home-item-radius;
                }
            }

            &:last-child {
                border-bottom-right-radius: $home-item-radius;
                border-top-right-radius: $home-item-radius;

                @include media-breakpoint-down(md) {
                    border-bottom-left-radius: $home-item-radius;
                    border-top-right-radius: 0;
                }
            }
        }

        .active {
            background-color: $black !important;
            color: $white !important;
            pointer-events: none;
            cursor: default;
        }
    }

    .author-archive {
        display: inline-block;
    }

    //Breadcrump
    #nav-breadcrumb {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .breadcrumb {
            background-color: lighten($black, 95%);

            .breadcrumb-item {
                +:before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f105";
                    vertical-align: top;
                    color: $black;
                }
            }

            .active {
                color: $gray !important;
            }

            a {
                color: $darkgray;

                &:hover {
                    text-decoration: none;
                    color: $gray;
                }
            }
        }
    }

    //Content Textarea
    #textarea {
        p,
        li {
            a {
                border-bottom: 1px solid $red;

                &:hover {
                    text-decoration: none;
                    border-bottom: 1px dotted $red;
                }
            }
        }
    }

    .quote,
    blockquote {
        border-left: 4px solid $lightgray;
        padding-left: 2rem;
        color: darken($gray, 25%);

        pre {
            color: darken($gray, 25%);
        }
    }

    //Document Icons
    #documents {
        a[href$=".pdf"]:before {
            @include fileicon('\f1c1');
        }

        a[href$=".doc"]:before,
        a[href$=".docx"]:before {
            @include fileicon('\f1c2');
        }

        a[href$=".xls"]:before,
        a[href$=".xlsx"]:before {
            @include fileicon('\f1c3');
        }

        a[href$=".ppt"]:before,
        a[href$=".pptx"]:before,
        a[href$=".pps"]:before,
        a[href$=".ppsx"]:before {
            @include fileicon('\f1c4');
        }

        a[href$=".zip"]:before,
        a[href$=".tar"]:before,
        a[href$=".rar"]:before {
            @include fileicon('\f1c6');
        }
    }

    //Pagination
    #nav-pagination {
        .pagination {
            justify-content: center;
            padding-bottom: 2rem;

            // font-size: .8rem;
            .page-item {
                span {
                    color: $gray;
                }

                &:hover {
                    span {
                        color: $darkgray;
                    }
                }
            }

            .page-link {
                &:hover {
                    background-color: lighten($black, 95%);
                }
            }

            .active {
                .page-link {
                    background-color: lighten($black, 15%) !important;
                    border-color: lighten($black, 15%) !important;
                    color: $white;
                }
            }
        }

        .list-group-item-action:hover,
        .list-group-item-action:focus {
            background-color: lighten($black, 95%);
        }
    }

    //Homepage Slider Sections
    #home-slider {
        .carousel-inner {
            .carousel-item {
                height: 33rem;
                overflow: hidden;
                position: relative;

                @include media-breakpoint-down(md) {
                    height: 16.5rem;
                }

                a {
                    img {
                        position: absolute;
                        border-radius: 0 !important;
                        margin: auto;
                        min-height: 100%;
                        min-width: 100%;
                        left: -100%;
                        right: -100%;
                        top: -100%;
                        bottom: -100%;

                        @include media-breakpoint-down(md) {
                            width: 100%;
                            height: auto;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }

                .carousel-caption {
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: $home-item-radius;
                }
            }
        }
    }

    //Homepage Welcome Text Sections
    #section-welcome {
        // background-color: lightgreen;
        // padding-left: 20rem;
        margin-top: 2rem;
        .section-title {
            display: none;
        }
        .inner-container {
            text-decoration: none;
            cursor: default;
            pointer-events: none;

            
            
            .image-wrapper {
                padding: 1rem 1rem 1rem 0;
                // width: 50%;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin-bottom: 2rem;
                }

                // float: left;

                .image {
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: auto;
                }

                img {
                    border-radius: $home-item-radius;
                }
            }

            .text {
                display: grid;
                
                grid-template-columns: auto 1fr;
                grid-template-areas: "t t" "d r";
                margin-bottom: 4rem;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding-left: .2rem;
                }

                .title {
                    color: $black;
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                    grid-area: t;
                }

                .description {
                    // max-width: 75ch;
                    grid-area: d;
                    text-align: left !important;
                    padding: 0 1rem 0 0;
                    p {
                        margin-bottom: 0;
                    }
                    &:after {
                        float: right;
                        @include readmore(xx);
                        color: darken($white, 15%);
                    }
                }

                .readmore {
                    display: none;
                    grid-area: r;
                    place-self: center stretch;
                    margin-right: .5rem;

                    &:hover {
                        text-decoration: none;
                    }
                    &::after {
                        @include readmore('\f105');
                        font-weight: 900; 
                        color: $arrows !important;
                    }
                }
            }
            
        }
    }

    //Homepage Events Sections
    #section-events {
        min-height: 27rem !important;
        float: left;
        max-width: 65%;
        @include media-breakpoint-down(md) {
            float: none;
            min-width: 100% !important;
        }
        .section-title {
            color: $gray;
            height: 1.3rem;
            margin-bottom: 1.5rem;
            border-bottom: 2px solid $gray;
            text-align: center;
            padding: 0;
        }
        .all-wrapper {
            
            min-height: 20rem;
            .inner-container {

                text-decoration: none;
                .image-wrapper {
                    .image {
                        
                    }
                }
                .text {
                    display: none !important;
                }
            }
        }
    }

    //Homepage News Sections
    #section-news {
        float: right;
        max-width: 32%;
        min-height: 27rem !important;
        
        @include media-breakpoint-down(md) {
            float: none;
            min-width: 100% !important;
        }
        .section-title {
            color: $gray;
            height: 1.3rem;
            border-bottom: 2px solid $gray;
            text-align: center;
            margin-bottom: 1rem;
            
        }
        .all-wrapper {
            .inner-container {
                text-decoration: none;
                
                &:nth-of-type(n+4) {
                    display: none;
                }
    
                .image-wrapper {
                    display: none;
                }
    
                .text {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-template-areas: "t r";
                    padding: .5rem;
                    .title {
                        grid-area: t;
                        // font-size: 1.3rem;
                        padding: 0 1rem 1rem 0;
                        border-bottom: 1px solid $gray;
                    }
                    .description {
                        display: none;
                    }
                    .readmore {
                        padding-bottom: 1rem;
                        grid-area: r;
                        place-self: center stretch;
                        &:hover {
                            text-decoration: none;
                        }
                        &::after {
                            @include readmore('\f105');
                            font-weight: 900;
                            color: $arrows;
                        }
                    }
                }
            }
        }

    }

    //Homepage Buttons Sections
    #section-button {
        .homebutton {
            display: flex !important;
            display: block;
            width: 100%;
            text-align: center;

            background-color: lighten($theme-main, 10%) !important;
            margin: .5rem .5rem .5rem 0;
            padding: .5rem;

            &:last-child {
                margin-right: 0 !important;

                @include media-breakpoint-down(md) {
                    // margin-right: .5rem !important;

                }
            }

            a {
                border-radius: $home-item-radius;
                width: 100%;
                height: 100%;
                color: $darkgray;

                span {
                    margin: .5rem;
                }

                &:hover {
                    color: $white;
                    text-decoration: none;
                    background-color: darken($theme-main, 10%);
                }
            }

            @include media-breakpoint-down(md) {
                height: auto;
                // width: 95%;
            }
        }
    }

    //Homepage Testimonials Sections
    #testimonials {
        background-color: $lightgray;
        border-radius: $home-item-radius;

        .carousel-inner {
            border: 1px solid darken($lightgray, 15%);
            border-radius: $home-item-radius * 4.5;
        }
    }

    //Products
    #variations {
        .nav-item {
            .nav-link {
                color: $black;
            }

            .active {
                color: $white;
                background-color: $gray;
            }
        }
    }

    #buyers {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.top-info {
    transition: all 500ms ease 0s;
    position: fixed;
    top: -#{$nav-bar-height};
    height: $nav-bar-height;
    padding: 1rem;
    z-index: 1061;
    // background-color: $theme-main;
    @include gradientbg();
    color: darken($gray, 40%);

    @include media-breakpoint-down(md) {
        padding: 1rem .5rem .5rem .5rem;
    }

    h6 {
        padding-right: 2rem;

        a {
            color: $orange;
            display: inline-block;
            font-size: 2.1rem !important;
            font-weight: 400 !important;
            img {
                max-height: 2rem;
                margin-left: 1.5rem;
            }

            &:hover {
                text-decoration: none;
            }
        }
        letter-spacing: .5rem;
        small {
            display: inline-block;

            letter-spacing: 0;
            max-width: 36ch;
            text-align: left;
            color: $white;
            font-size: .8rem !important;
            font-weight: 400;
        }

        @include media-breakpoint-down(md) {
            font-size: .7rem !important;
            padding-right: 1rem;
            margin-left: -1rem;

            #scrolltop {
                display: none;
            }
            small {
                
            display: none;
            }
        }

        span {
            cursor: pointer;
            
        }
    }
}

#nav-at-top {
    .navbar {
        transition: all 500ms ease 0s;
        top: $nav-bar-height;
    }

    .top-info {
        @include animation;
        top: 0;
    }

    h1 {
        // @include animation;
        padding-top: calc(#{$nav-bar-height} * 1.5) !important;
    }
}

#footerlogoband {
    display: none;
    background-color: $gray;
    // height: $footer-height / 1.5;
    padding: .75rem 3rem;

    img {
        max-height: 3rem;
        width: auto;
        margin-right: 2rem;
    }
}

footer {
    overflow-x: hidden;
    position: absolute;
    bottom: 0;
    min-height: $footer-height;
    width: 100%;
    // background-color: $darkgray;
    @include gradientbg();
    color: $white;
    padding-left: 3rem;

    @include media-breakpoint-down(md) {
        bottom: -1px;
        text-align: center !important;
    }

    a,
    a:hover {
        color: $white;
    }

    .addressbar {
        height: calc(#{$footer-height} / 1.5);

        @include media-breakpoint-down(md) {
            min-height: calc(#{$footer-height} * 2);
        }
    }

    address {
        #mapModal .modal-dialog {
            -webkit-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
            top: 50%;
            margin: 0 auto;
        }

        div {
            margin-bottom: .5rem;

            i {
                display: inline-block;
                margin-top: .2rem;
            }
        }
    }

    .socialmedia {
        @include media-breakpoint-down(md) {
            text-align: center !important;
        }
    }
}