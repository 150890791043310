@import "../assets/fontawesome/css/all.css";
@import "../assets/fancybox/dist/jquery.fancybox.min.css";
@import "../assets/bootstrap/scss/functions.scss";
@import "../assets/bootstrap/scss/variables.scss";

$theme-main :#27317b;

$black :#000000;
$white :#ffffff;

$lightgray :#ccc;
$gray :#aeb1b0;
$darkgray :darken($dark, 10%);
$lightblue: #68747d;
$arrows: #008ff0;

$gradientdark: #171920;
$gradientlight: #414850;


$red :#FC5753;
$yellow :#FDBC40;
$green :#34C84A;
$blue :#acdffc;
$orange :#c28544;


$one-third :31.66%;
$one-fourth :23.115%;
$cover-image: 15rem;

$news-item-height :300px;
$events-item-height :330px;
$welcome-image-size :400px;
$home-item-radius :.25rem;
$image-radius :.5rem;
$footer-height :200px;
$nav-bar-height :60px;

//Navbar Background overwrite
// $dropdown-bg            :$dark;
// $dropdown-link-color    :$light;

// $breadcrumb-divider: quote(">");
$theme-colors: (info: $theme-main);

@import "../assets/bootstrap/scss/bootstrap.scss";